<template>
  <div class="container">
    <main id="main_content">
    <h2 v-if="$user">Hello {{$user['name']}} - {{$user['sub']}} - id: {{$user['user_id']}}</h2>
    <section class="py-5 text-center container">
      <div class="row p-0">
        <div class="col-lg-8 col-md-12 mx-auto">
          <h1 class="fw-light">Page </h1>
          <input >
          <p class="lead text-muted" v-cloak> Something short and leading about the collection below—its contents, the creator, etc. Make it short and sweet, but not too short so folks don’t simply skip over it entirely.</p>
          <p>
            <router-link :to="{name: 'login'}" class="btn btn-primary my-2">Login</router-link>
            <router-link :to="{name: 'register'}" class="btn btn-primary my-2">Register</router-link>
            <b-button v-if="$user" v-on:click="logout" class="bg-primary">Logout</b-button>
          </p>
          <p>
            <b-button v-if="$user" v-on:click="get_auth_user" class="bg-primary">Get auth user</b-button>
            <b-button v-if="$user" v-on:click="test_authz" class="bg-primary">Get Admin Response</b-button>
            <br>Auth user: {{authUser}}
            <br>Admin Product: {{adminProduct}}
          </p>
        </div>
      </div>
    </section>
  <!-- <img class="d-none" rel="prefetch" src="https://images.unsplash.com/photo-1533450718592-29d45635f0a9"> -->
    <div class="album">
      <b-row><b-col cols="12" class="text-center">
              <b-pagination v-if="totalItems > itemsPerPage"
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="9"
                first-number
                last-number
                align="center"
              ></b-pagination>
              </b-col>
      </b-row>
      <transition :duration="{ enter: 250, leave: 150 }"
        enter-active-class="animated fadeIn faster"
        leave-active-class="animated fadeOut faster" 
        mode="out-in">
      <div class="container p-0" v-if="items.length > 0" :key="currentPage">
        <b-row>
          <b-col lg="4" cols="12" sm="6" v-for="(item, idx) in items" :key="idx" class="mb-3 mt-2">
              <router-link :to="{name: 'product', params: {id: item.id, slug: item.name}}" class="d-block">
            <div class="card shadow-sm">
                <img alt="#" :src="item.image">
                <div class="card-body">
                  <p class="card-text">{{item.name}}<br>id: {{item.id}}</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <!-- <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-outline-secondary">View</button>
                      <button type="button" class="btn btn-sm btn-outline-secondary">Edit</button>
                    </div> -->
                    <small class="text-muted">{{item.price}}€</small>
                  </div>
                </div>
            </div>
              </router-link>
          </b-col>
        </b-row>
      </div>
          </transition>
      <b-row><b-col cols="12" class="text-center">
              <b-pagination v-if="totalItems > itemsPerPage"
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="9"
                first-number
                last-number
                align="center"
              ></b-pagination>
              </b-col>
      </b-row>
    </div>
  
  </main>
  </div>
</template>

<script>

export default {
  name: 'Home',
  // props: ['totalItems'],
  // metaInfo: {
  //   title: 'My Awesome Webapp',
  //   // override the parent template and just use the above title only
  //   titleTemplate: null
  // },
  metaInfo() {
        return { 
            // title: ((this.$user) ? this.$user.username+ ' - ' : 'No user - ') + " Epiloge - Build your network in your field of interest",
            meta: [
                { name: 'description', content:  'Epiloge is about connecting in your field of interest. Our vision is to help people share their knowledge, work, projects, papers and ideas and build their network through what they do rather where they live, study or work.'},
                { property: 'og:title', content: "Epiloge - Build your network in your field of interest"},
                { property: 'og:site_name', content: 'Epiloge'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    },
  data() {
    return {
      currentPage: parseInt(this.$route.params.page),
      totalItems: 0,
      itemsPerPage: 9,
      authUser: null,
      adminProduct: null,
      items: []
    };
  },
  watch: {
    currentPage: function(new_val) {
      if(parseInt(this.$route.params.page) != new_val) {
        this.$router.push({name: this.$route.name, params: {page: new_val}});
      }
    },
    '$route.params.page': function(new_val) {
      this.currentPage = parseInt(new_val);
      this.getProducts()
    }
  },
  methods: {
    logout() {
      this.authUser = null;
      this.adminProduct = null;
      fetch(process.env.VUE_APP_API_URL+ '/logout', {
        method: 'GET', // or 'PUT'
        headers: {
          'Authorization': 'Bearer ' +this.$user.access_token,
        },
      })
      .then(r =>  r.json().then(data => ({status: r.status, body: data})))
      .then(() => {
        this.$user = null;
        localStorage.removeItem('user');
      });
      /* .catch((error) => {
        console.log(error);
      }); */
    },
    get_auth_user: async function () {
      const resp = await fetch(process.env.VUE_APP_API_URL+ '/user', {
        method: 'GET', // or 'PUT'
        headers: {
          'Authorization': 'Bearer ' +this.$user.access_token,
        },
      });
      if(resp.status == 200) {
        this.authUser = await resp.json();
      }
      else {
        this.logout();
      }
      return resp.status;
    },
    getProducts() {
      this.items = [];
      fetch(process.env.VUE_APP_API_URL+ '/products?pagenum='+this.currentPage, {
        method: 'GET', // or 'PUT'
      })
      .then(r =>  r.json().then(data => ({status: r.status, body: data})))
      .then((response) => {
        if(response.status == 200) {
          this.items = response.body.products;
          this.totalItems = response.body.total_results;
          this.itemsPerPage = response.body.results_per_page;
        }
      });
      /* .catch((error) => {
        console.log(error);
      }); */
    },
    test_authz: async function() {
      if(await this.get_auth_user() != 200) return;
      fetch(process.env.VUE_APP_API_URL+ '/product-admin/1', {
        method: 'GET', // or 'PUT'
        headers: {
          'Authorization': 'Bearer ' +this.$user.access_token,
        },
      })
      .then(r =>  r.json().then(data => ({status: r.status, body: data})))
      .then((response) => {
        this.loading = false;
        this.adminProduct = response.body;
      });
    },
  },
  created() {
    this.getProducts();
  }
};
</script>