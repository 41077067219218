import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../pages/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: { name: 'apply' } },
  {
    path: '/admin',
    // beforeEnter(to, from, next) {
    beforeEnter() {
      // Put the full page URL including the protocol http(s) below
      window.location.replace(process.env.VUE_APP_API_URL+ '/en/admin/login');
    }
  },
  {
    path: '/home/:page?',
    name: 'home',
    component: HomeView
  },
  {
    path: '/apply',
    name: 'apply',
    component: () => import(/* webpackChunkName: "apply" */ '../pages/ApplyView.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "apply" */ '../pages/SearchView.vue')
  },
  { path: '*', redirect: { name: 'apply' } },
  // {
  //   path: '/chat/:room_id/:passcode',
  //   name: 'chat',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../pages/ChatView.vue')
  // }
]

const router = new VueRouter({
  mode: process.env.VUE_APP_ROUTER_MODE,
  base: process.env.VUE_APP_BASE_URL,
  routes
});

// router.beforeEach((to, from, next) => {
//   if(to.name == 'home' && to.params.page == undefined) {
//     next({name: 'home', params: {page: 1}});
//     return;
//   }
// 	next();
// });


export default router
